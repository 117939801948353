import { useState, useContext, useEffect, useRef, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import IsInternalContext from "#contexts/IsInternalContext";
import CognitoUserContext from "#contexts/CognitoUserContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { constant } from "#components/data/Constant";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

const NewPasswordRequired = (props) => {
  const navigate = useNavigate();
  const { cognitoUser } = useContext(CognitoUserContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const [password, setPassword] = useState(""); // コンポーネント間でのデータ共有のために Context API を使用している
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(null);
  const { isInternal, setIsInternal } = useContext(IsInternalContext);
  const refFirstRef = useRef(true);
  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* ローディングアニメーションの表示、非表示 */
  const [isLoading, setIsLoading] = useState(false);

  /* 未入力時のボタン活性・非活性 */
  const [notEntered, setNotEntered] = useState(true);

  /* 多言語対応 */
  const [t] = useTranslation();

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (process.env.NODE_ENV === "development") {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        if (isInternal === null) {
          if (sessionStorage.getItem("isInternal") === "true") {
            setIsInternal(true);
          } else {
            setIsInternal(false);
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, []);

  // 未入力時のボタン非活性対応
  useEffect(() => {
    clear();
    if (passwordConfirm.trim() !== "" && password.trim() !== "") {
      setNotEntered(false);
    } else {
      setNotEntered(true);
    }
  }, [password, passwordConfirm]);

  const clear = () => {
    setError("");
  };

  /* 入力チェック */
  const checkInput = async () => {
    clear();
    if (password !== passwordConfirm) {
      setError("E0001");
    } else {
      return true;
    }
    return false;
  };

  /**
   * @summary LocalStorage に OIDC Token を保存したうえで、サインインをする
   * @description LocalStorage に OIDC Token も保存する。
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in}
   */
  const handleNewPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if ((await checkInput()) !== true) {
      setIsLoading(false);
      return;
    }
    if (!cognitoUser) {
      if (isInternal === true) {
        navigate("/");
      } else {
        navigate("/external/");
      }
      return;
    }
    try {
      /**
       * 下記リンクより、Auth.signIn の引数 username には、ユーザ名とメールアドレスのどちらを設定しても良いと判断した。
       * @see {@link https://github.com/aws-amplify/amplify-js/blob/f0df916a9/packages/auth/src/Auth.ts#L1236}
       * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#completenewpassword}
       */

      await axios({
        method: "post",
        url: constant.CHANGE_INITIAL_PASSWORD,
        data: {
          cognitoUser,
          password,
        },
      });
      if (isInternal === true) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        navigate("/external/");
      }
    } catch (err) {
      if (err.response.data.code === "ContainPInfoPassword") {
        setError("E0008");
      } else if (err.response.data.code === "InvalidPasswordException") {
        setError("E0007");
      } else {
        setError("E0014");
        console.error("Unexpected error:", err.response.data.error);
      }
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleNewPassword} className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0005")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent sx={{ padding: "16px" }}>
          {t(error) && (
            <div className="error-div">
              {t(error)
                .split("\n")
                .map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {item}
                      <br />
                    </Fragment>
                  );
                })}
            </div>
          )}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              placeContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label={t("L0014")}
              variant="standard"
              name="password"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>

          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              placeContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label={t("L0015")}
              variant="standard"
              name="passwordConfirm"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
              type={showPasswordConfirm ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle passwordConfirm visibility"
              onClick={handleClickShowPasswordConfirm}
              onMouseDown={handleMouseDownPassword}
            >
              {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "300px", height: "30px" }}
          disabled={notEntered}
        >
          {t("L0016")}
        </Button>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">{t("L0010")}</Link>}
          {!isInternal && <Link to="/external/">{t("L0010")}</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}></RingSpinnerOverlay>
      <Footer fontSize="14px" />
    </form>
  );
};

export default NewPasswordRequired;
