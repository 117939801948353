import "#App.css";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { useState, useEffect } from "react";

const Multilingual = () => {
  /* 多言語対応 */
  const [t, i18n] = useTranslation();
  const [selectedLanguage, setselectedLanguage] = useState(
    localStorage.getItem("i18nextLng"),
  );
  // セレクトボックスの変更をハンドリング
  const handleChange = (event) => {
    setselectedLanguage(event.target.value);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <header className="header-auth">
      <Select
        className="select-language"
        value={selectedLanguage}
        onChange={handleChange}
        displayEmpty
        renderValue={() => (
          <div className="language-div">
            <LanguageIcon style={{ marginRight: 8 }} />
            {t("L0018")}
          </div>
        )}
      >
        <MenuItem value={"ja"}>日本語</MenuItem>
        <MenuItem value={"en"}>English</MenuItem>
      </Select>
    </header>
  );
};

export default Multilingual;
