import {
  useState,
  useLayoutEffect,
  useContext,
  useEffect,
  Fragment,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Auth, Amplify } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import CognitoUserContext from "#contexts/CognitoUserContext";
import PasswordContext from "#contexts/PasswordContext";
import RedirectUrlContext from "#contexts/RedirectUrlContext";
import SiteContext from "#contexts/SiteContext";
import SystemcodeContext from "#contexts/SystemcodeContext";
import IsInternalContext from "#contexts/IsInternalContext";
import IsAuthorizationContext from "#contexts/IsAuthorizationContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import {
  AccountCircle,
  Visibility,
  VisibilityOff,
  Lock,
} from "@mui/icons-material";
import { constant } from "#components/data/Constant";
import propTypes from "prop-types";
import awsExports from "#aws-exports";
import awsExportsEx from "#AwsExportsExternal";
import customAuth from "#CustomAuth";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

const SignIn = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [error, setError] = useState(""); // エラー有無
  const [webClientId, setWebClientId] = useState("");
  const { setCognitoUser } = useContext(CognitoUserContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const { password, setPassword } = useContext(PasswordContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const { redirectUrl, setRedirectUrl } = useContext(RedirectUrlContext);
  const { site, setSite } = useContext(SiteContext);
  const { setIsInternal } = useContext(IsInternalContext);
  const { systemcode, setSystemcode } = useContext(SystemcodeContext);
  const { isAuthorization, setIsAuthorization } = useContext(
    IsAuthorizationContext,
  );

  /* ローディングアニメーションの表示、非表示 */
  const [isLoading, setIsLoading] = useState(false);

  /* 未入力時のボタン活性・非活性 */
  const [notEntered, setNotEntered] = useState(true);

  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* 多言語対応 */
  const [t] = useTranslation();

  /**
   * @summary CompleteSignIn.jsx コンポーネントで referrer の値を取得するための設定処理である。
   * @description document.referrer はページ遷移をするたびに変化するので、次の要領で初回アクセス時の referrer を保存するようにした。
   *  01. SignIn.jsx が初めてレンダリングされるとき、referrer は null or '' である。
   *  02. 上記 01 の場合は、useEffectフック内の条件 if (!referrer) が true となり、document.referrer の値が setReferrer を使って保存される。
   *  03. referrer が一度セットされた後は、以降のレンダリングで useEffect フックが実行されても、referrer の状態は更新されなくなる。
   */
  useLayoutEffect(() => {
    (async () => {
      // 社内外部判定
      if (props.isInternal) {
        const fixedConfig = { ...awsExports, ...customAuth };
        Amplify.configure(fixedConfig);
        setIsInternal(true);
        setWebClientId(awsExports.aws_user_pools_web_client_id);
      } else {
        const fixedConfig = { ...awsExportsEx, ...customAuth };
        Amplify.configure(fixedConfig);
        setIsInternal(false);
        setWebClientId(awsExportsEx.aws_user_pools_web_client_id);
      }

      if (await isInvalidURLParam(props)) {
        navigate("/404");
      } else {
        if (props.mode === "signOut") {
          try {
            Auth.signOut({ global: true });
            localStorage.removeItem("idToken");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("username");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken.payload.exp");
            console.log("サインアウト完了");
          } catch (err) {
            console.error("Error signing out:", err);
          }
        } else if (props.mode === "signIn") {
          await isSignedIn();
        }
      }
      setUsername("");
      setPassword("");
    })();
  }, []);

  // 未入力時のボタン非活性対応
  useEffect(() => {
    if (username.trim() !== "" && password.trim() !== "") {
      setNotEntered(false);
    } else {
      setNotEntered(true);
    }
  }, [username, password]);

  /**
   * URLパラメータチェック
   */
  const isInvalidURLParam = async (props) => {
    if (!redirectUrl) {
      if (
        props.redirectUrl === "" ||
        !document.referrer ||
        !props.redirectUrl.includes(document.referrer)
      ) {
        return true;
      } else {
        setRedirectUrl(props.redirectUrl);
      }
    }
    if (!site) {
      if (props.site !== "dy" && props.site !== "st") {
        return true;
      } else {
        setSite(props.site);
      }
    }
    if (!systemcode) {
      if (props.systemcode === "" || props.systemcode.length !== 8) {
        return true;
      } else {
        setSystemcode(props.systemcode);
      }
    }
    if (!isAuthorization) {
      if (props.isAuthorization === "" || props.isAuthorization === "false") {
        setIsAuthorization("false");
      } else if (props.isAuthorization === "true") {
        setIsAuthorization("true");
      } else {
        return true;
      }
    }

    if (props.mode !== "signOut" && props.mode !== "signIn") {
      return true;
    }

    return false;
  };

  /**
   * ログイン中か判定
   */
  const isSignedIn = async () => {
    try {
      // 認証画面と接続先システム間をループしてしまう問題を防ぐための処理
      const currentUserInfo = await Auth.currentUserInfo();
      if (currentUserInfo === null || currentUserInfo.username === undefined) {
        return;
      }

      const currentAuthUser = await Auth.currentAuthenticatedUser();
      const session = await Auth.userSession(currentAuthUser);

      if (!session?.isValid()) {
        console.log("セッションが無効です");
      } else {
        // アクセストークン
        localStorage.setItem("accessToken", session.accessToken.jwtToken);
        // IDトークン
        localStorage.setItem("idToken", session.idToken.jwtToken);
        // 更新トークン
        localStorage.setItem("refreshToken", session.refreshToken.token);
        // ユーザー名
        localStorage.setItem("username", session.accessToken.payload.username);
        if (props.isAuthorization !== "true") return navigate("/signedin");
        await axios({
          method: "post",
          url: constant.IS_ACCESS,
          data: {
            userId: currentUserInfo.username,
            systemcode: props.systemcode,
          },
        });
        navigate("/signedin");
      }
    } catch (err) {
      if (err.response.status && err.response.status === 400) {
        navigate("/access-error");
      } else {
        console.error(err);
      }
    }
  };

  /**
   * @summary LocalStorage に OIDC Token を保存したうえで、サインインをする
   * @description LocalStorage に OIDC Token も保存する。
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in}
   */
  const handleSignIn = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const deviceKey = localStorage.getItem(
      `CognitoIdentityServiceProvider.${webClientId}.${username}.deviceKey`,
    );

    try {
      /**
       * 下記リンクより、Auth.signIn の引数 username には、ユーザ名とメールアドレスのどちらを設定しても良いと判断した。
       * @see {@link https://github.com/aws-amplify/amplify-js/blob/f0df916a9/packages/auth/src/Auth.ts#L634}
       * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#signin}
       */
      const user = await Auth.signIn(username, password, {
        deviceKey,
        systemcode,
        isAuthorization,
      });
      setCognitoUser(user);
      if (user.challengeName === "CUSTOM_CHALLENGE") {
        if (!user.challengeParam || !user.challengeParam.email) {
          setError("E0016");
          setIsLoading(false);
          return;
        } else if (
          user.challengeParam.message &&
          user.challengeParam.message === "AccountLocked"
        ) {
          setError("E0012");
          setIsLoading(false);
          return;
        } else if (user.challengeParam.isDevice === "true") {
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${webClientId}.${username}.deviceKey`,
          );
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${webClientId}.${username}.deviceGroupKey`,
          );
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${webClientId}.${username}.randomPasswordKey`,
          );
          delete user.deviceGroupKey;
          delete user.deviceKey;
          delete user.randomPassword;
          setCognitoUser(user);
        }
        setUsername(""); // username をクリア
        /**
         * Note: ここでの password クリアは厳禁である。
         *       理由は、(OTPの)「再送信」(MultiFactorAuthentication.jsx) 時に password が空になり失敗するためである。
         */
        setIsLoading(false);
        navigate("/input-otp");
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIsLoading(false);
        navigate("/new-password");
      } else {
        // デバイス認証成功によりMFAスキップ
        localStorage.setItem(
          "accessToken",
          user.signInUserSession.accessToken.jwtToken,
        );
        // IDトークン
        localStorage.setItem(
          "idToken",
          user.signInUserSession.idToken.jwtToken,
        );
        // 更新トークン
        localStorage.setItem(
          "refreshToken",
          user.signInUserSession.refreshToken.token,
        );
        // ユーザー名
        localStorage.setItem(
          "username",
          user.signInUserSession.accessToken.payload.username,
        );
        setIsLoading(false);
        navigate("/signedin");
      }
    } catch (err) {
      if (err.code === "NotAuthorizedException") {
        if (err.message === "Incorrect username or password.") {
          err.message = "E0002";
        } else if (err.message === "Password attempts exceeded") {
          err.message = "E0004";
        }
      } else if (err.code === "UserNotConfirmedException") {
        err.message = "E0015";
      } else if (err.code === "InvalidParameterException") {
        err.message = "E0009";
      } else if (
        err.code === "InvalidLambdaResponseException" &&
        err.message === "Invalid lambda function output : Invalid JSON"
      ) {
        navigate("/access-error");
      }
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSignIn} className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0001")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent style={{ padding: "16px" }}>
          {t(error) && (
            <div className="error-div">
              {t(error)
                .split("\n")
                .map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {item}
                      <br />
                    </Fragment>
                  );
                })}
            </div>
          )}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label={t("L0001")}
              variant="standard"
              name="username"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </CardContent>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label={t("L0002")}
              variant="standard"
              name="password"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>
        </CardContent>

        <CardContent style={{ padding: "0 0 0 140px", fontSize: "14px" }}>
          <Link to="/forget-password">{t("L0003")}</Link>
        </CardContent>
        <CardContent>
          <Button
            type="submit"
            variant="contained"
            disabled={notEntered}
            sx={{ width: "300px", height: "30px" }}
          >
            {t("L0004")}
          </Button>
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}></RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

SignIn.propTypes = {
  redirectUrl: propTypes.string,
  site: propTypes.string,
  systemcode: propTypes.string,
  mode: propTypes.string,
  isInternal: propTypes.string,
  isAuthorization: propTypes.string,
};

export default SignIn;
