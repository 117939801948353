import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Auth } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import IsInternalContext from "#contexts/IsInternalContext";
import { TextField, Button, Card, CardContent } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

const ForgetPassword = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const { isInternal, setIsInternal } = useContext(IsInternalContext);
  const [error, setError] = useState(null); // エラー有無
  const refFirstRef = useRef(true);

  /* ローディングアニメーションの表示、非表示 */
  const [isLoading, setIsLoading] = useState(false);

  /* 未入力時のボタン活性・非活性 */
  const [notEntered, setNotEntered] = useState(true);

  /* 多言語対応 */
  const [t] = useTranslation();

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (process.env.NODE_ENV === "development") {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        if (isInternal === null) {
          if (sessionStorage.getItem("isInternal") === "true") {
            setIsInternal(true);
          } else {
            setIsInternal(false);
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, []);

  // 未入力時のボタン非活性対応
  useEffect(() => {
    if (username.trim() !== "") {
      setNotEntered(false);
    } else {
      setNotEntered(true);
    }
  }, [username]);

  /**
   * @summary Cognito に登録済みのメールアドレス宛に「認証コード」を送信する。
   * @description Cognito に登録されたメールアドレス宛に「認証コード」が送信される。
   * @see {@link https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password}
   * Note: ユーザ名を入力した場合でも、Cognito に登録されたメールアドレス宛に「認証コード」が送信される。
   * Note: 認証コード」と「OTP」は別物である。「認証コード」= Cognito がデフォルトで提供する機能。「OTP」は CUSTOM_AUTH で提供する機能である。
   */
  const handleSendConfirmCode = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      console.log(`[DEBUG][ForgetPassword] username => ${username}`);
      await Auth.forgotPassword(username);
      setIsLoading(false);
      navigate("/change-password", { state: { username } });
    } catch (err) {
      console.error("Error sending OTP:", err);
      setIsLoading(false);
      if (err.code === "LimitExceededException") {
        setError("E0003");
      } else if (err.code === "InvalidParameterException") {
        setError("E0009");
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <form onSubmit={handleSendConfirmCode} className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0003")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent>
          {t(error) && <div className="error-div">{t(error)}</div>}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              padding: "16px",
              justifyContent: "center",
            }}
          >
            <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label={t("L0001")}
              variant="standard"
              name="username"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </CardContent>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "300px", height: "30px", marginTop: "20px" }}
          disabled={notEntered}
        >
          {t("L0017")}
        </Button>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">{t("L0010")}</Link>}
          {!isInternal && <Link to="/external/">{t("L0010")}</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}></RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

export default ForgetPassword;
