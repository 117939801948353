import { Helmet } from "react-helmet-async";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

const NotFoundError = () => {
  /* 多言語対応 */
  const [t] = useTranslation();

  return (
    <form className="app">
      <Multilingual />
      <Helmet>
        <title>Not Found Error</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="290px" />
        <CardContent style={{ padding: "50px" }}>
          <div className="not-found-title">{t("T0006")}</div>
          <div className="not-found-sub">{t("L0013")}</div>
        </CardContent>
      </Card>
      <Footer fontSize="12px" />
    </form>
  );
};

export default NotFoundError;
