import { useState, useEffect, useContext, useRef, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Auth } from "aws-amplify";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import CognitoUserContext from "#contexts/CognitoUserContext";
import IsInternalContext from "#contexts/IsInternalContext";
import PasswordContext from "#contexts/PasswordContext";
import SystemcodeContext from "#contexts/SystemcodeContext";
import IsAuthorizationContext from "#contexts/IsAuthorizationContext";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Article } from "@mui/icons-material";
import propTypes from "prop-types";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

/**
 * @summary 引数で与えられたメールアドレスの一部をマスクして返却する。
 *
 * @function
 * @name maskEmail
 * @param {string} email - マスクされるメールアドレス。
 * @param {number} [mask_interval=4] - メールアドレスのマスク間隔。
 * @param {string} [mask_char='*'] - メールアドレスをマスクする際に使用する文字。
 * @returns {string} マスクされたメールアドレス。
 * @example
 * maskEmail("taro.yamada@example.com"); // tar*.yam*da@exa*ple.*om
 * maskEmail("taro.yamada@example.com", 3, '#'); // ta#o.y#ma#a@ex#mp*e.c#m
 */
function maskEmail(email, maskInterval = 4, maskChar = "*") {
  if (!email) return "";

  let result = "";
  let count = 0;

  for (let i = 0; i < email.length; i++) {
    if (email[i] === "@") {
      result += email[i];
      count = 0;
    } else if (email[i] === "." || email[i] === "-" || email[i] === "_") {
      result += email[i];
    } else {
      count++;
      if (count === maskInterval) {
        result += maskChar;
        count = 0;
      } else {
        result += email[i];
      }
    }
  }

  return result;
}

const MFA = (props) => {
  const navigate = useNavigate();
  const [onetimePass, setOnetimePass] = useState("");
  const [error, setError] = useState(null); // エラー有無
  const { cognitoUser, setCognitoUser } = useContext(CognitoUserContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const { password } = useContext(PasswordContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const [emailAddress, setEmailAddress] = useState("");
  const { isInternal } = useContext(IsInternalContext);
  const [isDevice, setIsDevice] = useState(false);
  const refFirstRef = useRef(true);
  const [sessionId, setSessionId] = useState(false);
  const { systemcode } = useContext(SystemcodeContext);
  const { isAuthorization } = useContext(IsAuthorizationContext);

  /* ローディングアニメーションの表示、非表示 */
  const [isLoading, setIsLoading] = useState(false);

  /* 未入力時のボタン活性・非活性 */
  const [notEntered, setNotEntered] = useState(true);

  /* 多言語対応 */
  const [t] = useTranslation();

  /**
   * @summary Auth.signIn (== 1段階目の認証) で得られたオブジェクトから、challengeParam.email を取り出す。
   * Note: 「Auth.currentUserInfo()」では email アドレスが得られなかった。(2023/09/11 近藤)
   * Note: コンポーネント本体で setEmailAddress を呼び出すと、コンポーネントの状態が更新されてレンダリングが発火する。
   *       その結果、無限レンダリングが発生してしまう。そこで、cognitoUser が変更した場合のみ setEmailAddress を呼び出すようにする。
   */
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (
      cognitoUser &&
      cognitoUser.challengeParam &&
      cognitoUser.challengeParam.email
    ) {
      const maskedEmail = maskEmail(cognitoUser.challengeParam.email);
      setEmailAddress(maskedEmail);
      setSessionId(cognitoUser.challengeParam.sessionId);
    } else {
      /**
       * Note: ブラウザのリロードが行われると、cognitoUser が消えてしまう。そこで、リロード時は sign in 画面に戻す。
       *       なお、「Auth.currentAuthenticatedUser()」を使うことで対策できるのかも知れないが未着手である。
       */
      if (process.env.NODE_ENV === "development") {
        if (refFirstRef.current) {
          refFirstRef.current = false;
          if (sessionStorage.getItem("isInternal") === "true") {
            navigate("/");
          } else {
            navigate("/external/");
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, [cognitoUser]);

  // 未入力時のボタン非活性対応
  useEffect(() => {
    if (onetimePass.trim() !== "") {
      setNotEntered(false);
    } else {
      setNotEntered(true);
    }
  }, [onetimePass]);

  /**
   * @summary Cognito に関連付けられた Lambda トリガーを使って、カスタム認証を利用する
   * @see {@link https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#confirm-sign-in-with-custom-challenge}
   */
  const handleConfirmCustomChallenge = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const session = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        onetimePass,
        { sessionId, systemcode, isAuthorization },
      );
      if (!session.signInUserSession) {
        if (session.challengeParam.message === "LimitMFAChallenge") {
          setError("E0010");
        } else if (session.challengeParam.message === "ExpiredCode") {
          setError("E0005");
        } else if (session.challengeParam.message === "CodeMisMatch") {
          setError("E0006");
        }
        setIsLoading(false);
      } else if (session.signInUserSession) {
        try {
          if (isDevice) {
            // デバイス認証を記憶する
            await Auth.rememberDevice();
            localStorage.setItem(
              `CognitoIdentityServiceProvider.${session.pool.clientId}.${session.attributes.email}.deviceKey`,
              session.signInUserSession.accessToken.payload.device_key,
            );
          }
        } catch (err) {
          console.err(err);
        }
        // アクセストークン
        localStorage.setItem(
          "accessToken",
          session.signInUserSession.accessToken.jwtToken,
        );
        // アクセストークの有効期限
        localStorage.setItem(
          "accessToken.payload.exp",
          session.signInUserSession.accessToken.payload.exp,
        );
        // IDトークン
        localStorage.setItem(
          "idToken",
          session.signInUserSession.idToken.jwtToken,
        );
        // 更新トークン
        localStorage.setItem(
          "refreshToken",
          session.signInUserSession.refreshToken.token,
        );
        // ユーザー名
        localStorage.setItem(
          "username",
          session.signInUserSession.accessToken.payload.username,
        );
        setIsLoading(false);
        navigate("/signedin");
      }
    } catch (err) {
      if (
        err.code === "InvalidLambdaResponseException" &&
        err.message === "Invalid lambda function output : Invalid JSON"
      ) {
        navigate("/access-error");
      } else {
        console.error("Unexpected error:", err);
        setError("E0013");
      }
      setIsLoading(false);
    }
  };

  const resend = async () => {
    try {
      /**
       * 下記リンクより、Auth.signIn の引数 username には、ユーザ名とメールアドレスのどちらを設定しても良いと判断した。
       * @see {@link https://github.com/aws-amplify/amplify-js/blob/f0df916a9/packages/auth/src/Auth.ts#L634}
       * @see {@link https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#signin}
       * Note: password は Signin.jsx で設定された値を利用する。
       */
      setError("");
      setIsLoading(true);
      const username = cognitoUser.getUsername();
      const user = await Auth.signIn(username, password, {
        deviceKey: null,
        systemcode,
        isAuthorization,
      });
      setCognitoUser(user);
      if (
        user.challengeParam.message &&
        user.challengeParam.message === "AccountLocked"
      ) {
        setError("E0011");
      }
      setIsLoading(false);
    } catch (err) {
      console.error("confirming sign in err:", err);
      err.message = "E0011";
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmCustomChallenge} className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0002")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardHeader
          title={t("L0005")}
          titleTypographyProps={{ variant: "h6" }}
          subheader={props.email}
          subheaderTypographyProps={{ variant: "h6" }}
        ></CardHeader>

        <div className="success-div">{emailAddress}</div>
        {t(error) && (
          <div className="error-div">
            {t(error)
              .split("\n")
              .map((item, index) => {
                return (
                  <Fragment key={index}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
          </div>
        )}
        <CardContent sx={{ display: "flex", alignItems: "flex-end" }}>
          <Article sx={{ color: "action.active", mr: 2, my: 0.5 }} />
          <TextField
            id="standard-basic"
            label={t("L0006")}
            variant="standard"
            name="onetimePass"
            style={{ width: "300px", padding: "0 0 0 0" }}
            onChange={(e) => {
              setOnetimePass(e.target.value);
            }}
          />
        </CardContent>

        <FormGroup style={{ padding: "5px 0" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="isDevice"
                onChange={(e) => {
                  setIsDevice(e.target.checked);
                }}
              />
            }
            label={t("L0007")}
          />
        </FormGroup>

        <CardContent>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "300px", height: "30px" }}
            disabled={notEntered}
          >
            {t("L0008")}
          </Button>
        </CardContent>
        <CardContent sx={{ padding: 0 }}>
          <Button
            onClick={resend}
            variant="outlined"
            sx={{ width: "300px", height: "30px", boxShadow: 1 }}
          >
            {t("L0009")}
          </Button>
        </CardContent>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">{t("L0010")}</Link>}
          {!isInternal && <Link to="/external/">{t("L0010")}</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}></RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

MFA.propTypes = {
  email: propTypes.string,
};

export default MFA;
