import { useState } from "react";
import IsAuthorizationContext from "#contexts/IsAuthorizationContext";
import propTypes from "prop-types";

const IsAuthorizationProvider = ({ children }) => {
  const [isAuthorization, setIsAuthorization] = useState(null);

  return (
    <IsAuthorizationContext.Provider
      value={{ isAuthorization, setIsAuthorization }}
    >
      {children}
    </IsAuthorizationContext.Provider>
  );
};

IsAuthorizationProvider.propTypes = {
  children: propTypes.object,
};

export default IsAuthorizationProvider;
