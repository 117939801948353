const constant = {
  // シークレットキー取得APIエンドポイント
  IS_ACCESS: "https://p2umh2jqb3.ap-northeast-1.awsapprunner.com/isAccess/",
  // パスワードチェック判定エンドポイント
  FORGOT_PASSWORD_CONFIRM:
    "https://p2umh2jqb3.ap-northeast-1.awsapprunner.com/forgotPasswordSubmit/",
  // パスワードチェック判定エンドポイント
  CHANGE_INITIAL_PASSWORD:
    "https://p2umh2jqb3.ap-northeast-1.awsapprunner.com/changeInitialPassword/",
};

export { constant };
