import { useState, useContext, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import PasswordContext from "#contexts/PasswordContext";
import IsInternalContext from "#contexts/IsInternalContext";
import {
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
  Article,
} from "@mui/icons-material";
import { constant } from "#components/data/Constant";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null); // Amplifyエラー有無
  /* 入力 */
  const [username, setUsername] = useState(location.state.username);
  const { isInternal, setIsInternal } = useContext(IsInternalContext);
  const { password, setPassword } = useContext(PasswordContext); // コンポーネント間でのデータ共有のために Context API を使用している
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const refFirstRef = useRef(true);
  /* パスワードの表示、非表示 */
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* ローディングアニメーションの表示、非表示 */
  const [isLoading, setIsLoading] = useState(false);

  /* 未入力時のボタン活性・非活性 */
  const [notEntered, setNotEntered] = useState(true);

  /* 入力チェック */
  const checkInput = async () => {
    clear();
    if (password !== passwordConfirm) {
      setError("E0001");
    } else {
      return true;
    }
    return false;
  };

  /* 多言語対応 */
  const [t] = useTranslation();

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      sessionStorage.setItem("isInternal", isInternal);
    });

    if (process.env.NODE_ENV === "development") {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        if (isInternal === null) {
          if (sessionStorage.getItem("isInternal") === "true") {
            setIsInternal(true);
          } else {
            setIsInternal(false);
          }
          sessionStorage.removeItem("isInternal");
        }
      }
    }
  }, []);

  const clear = () => {
    setError("");
  };

  // 未入力時のボタン非活性対応
  useEffect(() => {
    clear();
    if (
      confirmationCode.trim() !== "" &&
      password.trim() !== "" &&
      passwordConfirm.trim() !== ""
    ) {
      setNotEntered(false);
    } else {
      setNotEntered(true);
    }
  }, [confirmationCode, password, passwordConfirm]);

  /**
   * @summary OnetimePass (OTP) と 新規パスワードを入力する
   * @description OTP と 新規パスワード の両方を同時に入力すること。Amplify SDK では OTP のみの認証はサポートしていない。
   * @see {@link https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password}
   * Note: Auth.forgotPasswordSubmit は username, OTP, 新規パスワード の 3つセットで使用する必要あり。
   */
  const handleConfirmOTPAndNewPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if ((await checkInput()) !== true) {
        setIsLoading(false);
        return;
      }

      await axios({
        method: "post",
        url: constant.FORGOT_PASSWORD_CONFIRM,
        data: {
          userId: username,
          confirmationCode,
          password,
          isInternal,
        },
      });

      if (isInternal) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        navigate("/external/");
      }
    } catch (err) {
      if (err.response.data.code === "ContainPInfoPassword") {
        setError("E0008");
      } else if (err.response.data.code === "InvalidPasswordException") {
        setError("E0007");
      } else if (err.response.data.code === "ExpiredCodeException") {
        setError("E0005");
      } else if (err.response.data.code === "CodeMismatchException") {
        setError("E0006");
      } else if (err.response.data.code === "LimitExceededException") {
        setError("E0003");
      } else {
        setError("E0014");
        console.error("Unexpected error:", err.response.data.error);
      }
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmOTPAndNewPassword} className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0004")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="240px" />
        <CardContent sx={{ padding: "16px" }}>
          {t(error) && (
            <div className="error-div">
              {t(error)
                .split("\n")
                .map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {item}
                      <br />
                    </Fragment>
                  );
                })}
            </div>
          )}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              padding: "16px",
              justifyContent: "center",
            }}
          >
            <AccountCircle sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label={t("L0001")}
              variant="standard"
              name="username"
              value={username}
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{ readOnly: true }}
            />
          </CardContent>

          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label={t("L0002")}
              variant="standard"
              name="password"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>

          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Lock sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-adornment-password"
              label={t("L0015")}
              variant="standard"
              name="passwordConfirm"
              style={{ width: "260px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
              type={showPasswordConfirm ? "text" : "password"}
            />
            <IconButton
              aria-label="toggle passwordConfirm visibility"
              onClick={handleClickShowPasswordConfirm}
              onMouseDown={handleMouseDownPassword}
            >
              {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </CardContent>

          <CardContent
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Article sx={{ color: "action.active", mr: 2, my: 0.5 }} />
            <TextField
              id="standard-basic"
              label={t("L0006")}
              variant="standard"
              name="confirmationCode"
              style={{ width: "300px", padding: "0 0 0 0" }}
              onChange={(e) => {
                setConfirmationCode(e.target.value);
              }}
            />
          </CardContent>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "300px", height: "30px" }}
          disabled={notEntered}
        >
          {t("L0016")}
        </Button>
        <CardContent sx={{ fontSize: "12px" }}>
          {isInternal && <Link to="/">{t("L0010")}</Link>}
          {!isInternal && <Link to="/external/">{t("L0010")}</Link>}
        </CardContent>
      </Card>
      <RingSpinnerOverlay loading={isLoading}></RingSpinnerOverlay>
      <Footer fontSize="12px" />
    </form>
  );
};

export default ChangePassword;
