import { useLayoutEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import RedirectUrlContext from "#contexts/RedirectUrlContext";
import SiteContext from "#contexts/SiteContext";
import "#App.css";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";

const CompleteSignIn = () => {
  const { redirectUrl } = useContext(RedirectUrlContext);
  const { site } = useContext(SiteContext);

  /* 多言語対応 */
  const [t] = useTranslation();

  /**
   * @summary OIDC Token を共用するために、クライアントのエンドポイント「/callback」に OIDC Token を送信する。
   * @details CompleteSignIn がマウントされたとき (= 初回のみ)実行するように、useEffect(～, []) としている。
   */
  useLayoutEffect(() => {
    (async () => {
      const accessToken = localStorage.getItem("accessToken");
      const idToken = localStorage.getItem("idToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const username = localStorage.getItem("username");

      let callbackUrl = redirectUrl;
      if (callbackUrl) {
        if (site === "st") {
          /** 静的ホスティングのクライアントアプリの場合 */
          callbackUrl =
            callbackUrl +
            `?accessToken=${encodeURIComponent(accessToken)}` +
            `&idToken=${encodeURIComponent(idToken)}` +
            `&refreshToken=${encodeURIComponent(refreshToken)}` +
            `&username=${username}` +
            "#/callback";
          window.location.href = callbackUrl;
        } else if (site === "dy") {
          /** 動的ホスティング */
          // form を動的に生成
          const form = document.createElement("form");
          form.action = callbackUrl + "callback";
          form.method = "POST";
          // body に追加
          document.body.append(form);

          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (
              key !== "accessToken" &&
              key !== "idToken" &&
              key !== "refreshToken"
            )
              continue;
            const inputElement = document.createElement("input");
            inputElement.name = key;
            inputElement.value = localStorage.getItem(key);
            inputElement.style.display = "none";
            form.appendChild(inputElement);
          }
          form.submit();
        } else {
          callbackUrl = "/";
          window.location.href = callbackUrl;
        }
      }
    })();
  }, []);

  return (
    <div className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0008")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1, width: "470px" }}>
        <Header w="290px" />
        <CardContent
          style={{ fontSize: "14px", marginBottom: "40px", marginTop: "10px" }}
        >
          {t("L0011")}
        </CardContent>
      </Card>
      <Footer fontSize="14px" />
    </div>
  );
};

export default CompleteSignIn;
