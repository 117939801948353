import { Helmet } from "react-helmet-async";
import Header from "#common/layout/Header";
import Footer from "#common/layout/Fotter";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import Multilingual from "#common/layout/Multilingual";
import { Fragment } from "react";

const AccessError = () => {
  /* 多言語対応 */
  const [t] = useTranslation();

  return (
    <form className="app">
      <Multilingual />
      <Helmet>
        <title>{t("T0007")}</title>
      </Helmet>
      <Card className="forms" sx={{ boxShadow: 1 }}>
        <Header w="290px" />
        <CardContent style={{ padding: "50px" }}>
          <div className="access-error-div">
            {t("L0012")
              .split("\n")
              .map((item, index) => {
                return (
                  <Fragment key={index}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
          </div>
        </CardContent>
      </Card>
      <Footer fontSize="12px" />
    </form>
  );
};

export default AccessError;
